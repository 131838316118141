import request from "@/api/axios";

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid,
  };
  return request({
    url: "/api/v1/login",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}
// 登录方法
export function ipLogin() {
  // const data = {
  //   uuid
  // }
  return request({
    url: "/api/v1/user/ipLogin",
    headers: {
      isToken: false,
    },
    method: "post",
  });
}

// 注册方法
export function register(data) {
  return request({
    url: "/api/v1/register",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: "/api/v1/getInfo",
    method: "get",
  });
}

// 退出方法
export function logout() {
  return request({
    url: "/api/v1/logout",
    method: "post",
  });
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: "/api/v1/captchaImage",
    headers: {
      isToken: false,
    },
    method: "get",
    timeout: 20000,
  });
}
// 校验密码是否正确方法
export function loginCheck(data) {
  return request({
    url: "/api/v1/loginCheck",
    method: "post",
    data: data,
  });
}
// 校验密码是否正确方法
export function sendLoginCheckCode(data) {
  return request({
    url: "/api/v1/sendLoginCheckCode",
    method: "post",
    data: data,
  });
}
// 验证码登录
export function checkCodeLogin(username, password, code) {
  const data = {
    username,
    password,
    code,
  };
  return request({
    url: "/api/v1/checkCodeLogin",
    method: "post",
    data: data,
  });
}

// 获取加密 秘钥
export function getPublicKey() {
  return request({
    url: "/api/v1/user/getPublicKey",
    method: "get",
  });
}
// 修改密码
export function UpdatePwd(data) {
  return request({
    url: "/api/v1/forceUpdatePwdAfter90Days",
    method: "post",
    data: data,
  });
}
