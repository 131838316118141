/* 1.引入文件 */
import axios from "axios"; //引入 axios库
import baseurl from "./baseurl.js";
import { Message, MessageBox, Loading } from "element-ui";
import { getToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";
import store from "@/store";

const baseURL = baseurl.baseUrl;
const offset = 400;
// const token = localStorage.getItem('token')
const token = "Bearer " + getToken();
const service = axios.create({
  baseURL,
  timeout: 3000000,
  withCredentials: true,
});
service.interceptors.request.use(
  config => {
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (config.method == "get") {
      //判断get请求
      config.params = config.params || {};
      config.params.t = Date.parse(new Date()) / 1000; //添加时间戳  防止接口缓存
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200 || 50001;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    // 二进制数据则直接返回
    if (res.request.responseType === "blob" || res.request.responseType === "arraybuffer") {
      return res.data;
    }
    if (code === 401) {
      store.dispatch("LogOut").then(() => {
        location.href = "/#/login";
      });
      // MessageBox.confirm("您的登录状态已过期，请重新登录", "系统提示", {
      //   showClose: false,
      //   showCancelButton: false,
      //   confirmButtonText: "重新登录",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     store.dispatch("LogOut").then(() => {
      //       location.href = "/#/login";
      //     });
      //   })
      //   .catch(() => {});
      return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
    } else if (code === 500) {
      if (msg.indexOf("IP登录") == -1) {
        Message({
          message: msg,
          type: "error",
          offset,
        });
      }
      return Promise.reject(new Error(msg));
    } else if (code !== 200 && code != 50001) {
      if (msg.includes("没有权限")) {
        return;
      } else {
        Message({
          message: msg,
          type: "error",
          // duration: 3000,
          offset,
          // center:true
        });
      }

      return Promise.reject(new Error(msg));
    } else {
      return res.data;
    }
  },
  error => {
    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: "error",
      duration: 1500,
      offset,
    });
    return Promise.reject(error);
  }
);

export default service;
