import request from "@/api/axios";

// 首页接口
export function indexSubjectCount() {
  return request({
    url: "/api/v1/business/data/indexSubjectCount",
    method: "post",
  });
}
// 首页图书分类接口
export function indexSubjectList() {
  return request({
    url: "/api/v1/business/data/indexSubjectList",
    method: "post",
  });
}
// 首页检索-书目列表
export function searchDataList(data) {
  return request({
    url: "/api/v1/business/data/searchDataList",
    method: "post",
    data: data,
  });
}
// 高级检索-书目列表
export function seniorSearch(data) {
  return request({
    url: "/api/v1/business/data/seniorSearch",
    method: "post",
    data: data,
  });
}
// 左侧列表
export function searchLeft(data) {
  return request({
    url: "/api/v1/business/data/searchLeft",
    method: "post",
    data: data,
  });
}
// 左侧列表-高级检索
export function seniorSearchLeft(data) {
  return request({
    url: "/api/v1/business/data/seniorSearchLeft",
    method: "post",
    data: data,
  });
}
// 左侧列表
// export function getPdf(query) {
//     return request({
//       url: `/api/v1/business/data/pdf?dataId=${query.dataId}&archiveSid=${query.archiveSid}&page=0`,
//       method: 'get',
//       responseType: 'blob',
//     })
//   }
export function getPdf(query) {
  return request({
    url: `/api/v1/business/data/load?dataId=${query.dataId}&archiveSid=${query.archiveSid}&token=${query.token}&flag=${query.flag}`,
    method: "get",
  });
}
// 详情
export function getDetail(dataId) {
  return request({
    url: `/api/v1/business/data/detail/${dataId}`,
    method: "get",
  });
}

// 访问日志
export function recordVisit(pages, pageType) {
  return request({
    url: `/api/v1/logs/record/visit?page=${encodeURIComponent(pages)}&pageType=${pageType}`,
    method: "POST",
  });
}
// 阅读日志
export function recordReadLog(data) {
  return request({
    url: `/api/v1/logs/record/read`,
    method: "POST",
    data: data,
  });
}
