<template>
  <div class="login">
    <img style="margin-top: 180px" src="@/assets/images/logo.png" />
    <div class="form-content">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <el-form-item prop="username" style="margin-left: 192px; margin-top: 45px">
          <el-input class="logininput" style="width: 230px" v-model="loginForm.username" type="text" auto-complete="off" maxlength="20" placeholder="账户">
            <div slot="prefix">
              <img style="vertical-align: middle; margin-top: 7px; margin-left: 10px" src="@/assets/images/user.png" />
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" style="margin-left: 60px; margin-top: 45px">
          <el-input class="logininput" style="width: 230px" v-model="loginForm.password" type="password" auto-complete="off" maxlength="20" placeholder="密码" @keyup.enter.native="handleLogin">
            <div slot="prefix">
              <img style="vertical-align: middle; margin-top: 7px; margin-left: 10px" src="@/assets/images/password.png" />
            </div>
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left: 60px; margin-top: 45px">
          <div @click.prevent="handleLogin" class="btnLogin">
            <img :src="loginurl" style="display: block" @mouseenter="loginurl = logindeselect" @mouseleave="loginurl = logindefault" />
          </div>
        </el-form-item>
        <el-form-item style="margin-left: 32px; margin-top: 45px">
          <img src="@/assets/images/line.png" style="display: block" />
        </el-form-item>
        <el-form-item style="margin-left: 26px; margin-top: 45px">
          <div @click.prevent="handleIpLogin" class="btnLogin">
            <img :src="ipurl" style="display: block" @mouseenter="ipurl = ipselect" @mouseleave="ipurl = ipdefault" />
          </div>
        </el-form-item>
        <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 40px 60px;">记住密码</el-checkbox> -->
        <!-- <el-form-item style="text-align:center">
          <el-button
            
            :loading="loading"
            size="medium"
            type="primary"
            style="width:110px;"
            @click.native.prevent="handleLogin"
          >
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
          <div style="float: right;" v-if="register">
            <router-link class="link-type" :to="'/register'">立即注册</router-link>
          </div>
        </el-form-item> -->
      </el-form>
    </div>

    <!--  底部  -->
    <div class="el-login-footer">
      <p style="opacity: 0.5">
        {{ footerForm.configValue }}
        <!-- 化学工业出版社有限公司 版权所有 |
        联系电话：010-64519105（座机）；18600895080（手机） -->
      </p>
      <p>
        <span style="opacity: 0.5" @click="handleOpenLicence" class="linkurl">网络出版服务许可证</span>
        <img style="vertical-align: middle; margin-right: 5px" src="@/assets/images/wj.png" />
        <span style="opacity: 0.5" @click="handleOpenArchival(1)" class="linkurl">京公网安备 11010102002361号</span>
        <span style="opacity: 0.5" @click="handleOpenArchival(2)" class="linkurl">京ICP备12046843号-10</span>
      </p>
    </div>
    <!-- 手机验证码弹框 -->
    <el-dialog title="手机验证" :visible.sync="openView" width="450px" append-to-body>
      <p>验证码已发送至账号预留手机{{ phone }}</p>
      <div class="codeVal">
        <el-input v-model="loginForm.phoneCode" placeholder="6位数验证码" maxlength="6"> </el-input>

        <el-button class="phoneCode" v-if="!codeFlag" @click="sendPhoneCode" plain> 获取验证码 </el-button>
        <el-button class="phoneCode" v-else type="primary"> {{ times }}s后重新发送 </el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPhone">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCodeImg, loginCheck, sendLoginCheckCode, getPublicKey, UpdatePwd } from "@/api/login";
import { list } from "@/api/setting.js";
import Cookies from "js-cookie";
import { encrypt, decrypt } from "@/utils/jsencrypt";
import logindefault from "@/assets/images/btn-denglu-default.png";
import logindeselect from "@/assets/images/btn-denglu-select.png";
import ipdefault from "@/assets/images/btn-IPdenglu-default.png";
import ipselect from "@/assets/images/btn-ipdenglu-select.png";
import licenseImg from "@/assets/images/wlcbfwxkz.jpg";

import JSEncrypt from "jsencrypt";
let Base64 = require("js-base64").Base64;
export default {
  name: "Login",
  data() {
    var checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入您的账户"));
      } else {
        let regx = /^[A-Za-z0-9@#$%^&*]+$/;
        if (!regx.test(value)) {
          callback(new Error("输入的账户有误"));
        } else {
          if (value.length < 1) {
            callback(new Error("输入的账户有误"));
          } else {
            callback();
          }
        }
      }
    };
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入您的密码"));
      }
      callback();
      // else {
      //   let regx = /^[A-Za-z0-9@#$%^&*]+$/;
      //   if (!regx.test(value)) {
      //     callback(new Error("输入的密码有误"));
      //   } else {
      //     if (value.length < 8) {
      //       callback(new Error("输入的密码有误"));
      //     } else {
      //       callback();
      //     }
      //   }
      // }
    };

    // var checkNewPassword = (rule, value, callback) => {
    //   if (value) {
    //     let regxs = /^[A-Za-z0-9@#$%^&*]+$/;
    //     if (!regxs.test(value)) {
    //       callback(new Error("请输入英文字母或数字或@#$%^&*"));
    //     } else {
    //       console.log(2222);
    //       callback();
    //     }
    //   }
    // };
    return {
      footerForm: "化学工业出版社有限公司 版权所有 |  联系电话：010-64519105（座机）；18600895080（手机）",
      logindefault,
      logindeselect,
      ipdefault,
      ipselect,
      loginurl: logindefault,
      ipurl: ipdefault,
      codeUrl: "",

      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
        phoneCode: "",
      },
      loginRules: {
        username: [
          { validator: checkUserName, trigger: "blur" },
          // { required: true, trigger: "blur", message: "请输入您的账户" }
        ],
        password: [
          { validator: checkPassword, trigger: "blur" },
          // { min: 8, max: 20, message: "长度在 8 到 20个字符" }
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }],
      },
      loading: false,
      // 验证码开关
      captchaOnOff: false,
      // 注册开关
      register: false,
      redirect: undefined,
      phone: "",
      openView: false,
      codeFlag: false,
      times: 60,
      encryptedPassword: "",
      encryptedUsername: "",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    // this.getCode();
    this.getCookie();
    this.getDetailByType("sys.footer.info");
  },
  methods: {
    // 获取页脚详情
    getDetailByType(type) {
      list(type).then(res => {
        if (type == "sys.footer.info") {
          this.footerForm = res.rows[0];
        }
      });
    },
    // 打开网络许可证
    handleOpenLicence() {
      window.open(licenseImg);
    },
    // 打开备案信息
    handleOpenArchival(type) {
      if (type == 1) {
        window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002361");
      } else {
        window.open("https://beian.miit.gov.cn/");
      }
    },
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    // handleLogin() {
    //   this.$refs.loginForm.validate(valid => {
    //     if (valid) {
    //       this.loading = true;
    //       if (this.loginForm.rememberMe) {
    //         Cookies.set("username", this.loginForm.username, { expires: 30 });
    //         Cookies.set("password", encrypt(this.loginForm.password), {
    //           expires: 30,
    //         });
    //         Cookies.set("rememberMe", this.loginForm.rememberMe, {
    //           expires: 30,
    //         });
    //       } else {
    //         Cookies.remove("username");
    //         Cookies.remove("password");
    //         Cookies.remove("rememberMe");
    //       }
    //       this.$store
    //         .dispatch("Login", this.loginForm)
    //         .then(() => {
    //           // this.$router.push({ path: this.redirect || "/" }).catch(() => {});
    //           this.$router.push({ path: "/" }).catch(() => {});
    //         })
    //         .catch(() => {
    //           this.loading = false;
    //           if (this.captchaOnOff) {
    //             this.getCode();
    //           }
    //         });
    //     }
    //   });
    // },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          getPublicKey().then(res => {
            const encrypt = new JSEncrypt();
            // 设置公钥（从后端获取并配置）
            const publicKey = `${res.data}`;
            encrypt.setPublicKey(publicKey);

            // 加密密码
            this.encryptedUsername = encrypt.encrypt(this.loginForm.username);
            this.encryptedPassword = encrypt.encrypt(this.loginForm.password);

            let postBody = {
              username: Base64.encode(this.encryptedUsername),
              password: Base64.encode(this.encryptedPassword),
            };
            clearInterval(this.timer);
            loginCheck(postBody).then(res => {
              this.phone = res.phoneNumber;
              this.openView = true;
              if (this.times == 60) {
                this.sendPhoneCode();
              } else {
                this.timer = setInterval(() => {
                  this.times--;
                  if (this.times === 0) {
                    this.codeFlag = false;
                    this.times = 60;
                    clearInterval(this.timer);
                  }
                }, 1000);
              }
            });
          });
        }
      });
    },
    // 倒计时
    sendPhoneCode() {
      let postBody = {
        username: Base64.encode(this.encryptedUsername),
        password: Base64.encode(this.encryptedPassword),
      };
      sendLoginCheckCode(postBody).then(res => {
        this.codeFlag = true;
        this.timer = setInterval(() => {
          this.times--;
          if (this.times === 0) {
            this.codeFlag = false;
            this.times = 60;
            clearInterval(this.timer);
          }
        }, 1000);
      });
    },
    submitPhone() {
      if (!this.loginForm.phoneCode) {
        this.$message.error("请输入有效的验证码");
        return;
      }
      this.loginForm.encryptedUsername = Base64.encode(this.encryptedUsername);
      this.loginForm.encryptedPassword = Base64.encode(this.encryptedPassword);
      this.$store
        .dispatch("codeLogin", this.loginForm)
        .then(res => {
          this.openView = false;
          this.$message.success("登录成功");
          this.$router.push({ path: "/" }).catch(() => {});
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleIpLogin() {
      this.loading = true;
      Cookies.remove("username");
      Cookies.remove("password");
      Cookies.remove("rememberMe");

      this.$store
        .dispatch("ipLogin")
        .then(res => {
          // this.$router.push({ path: this.redirect || "/" }).catch(() => {});
          this.$router.push({ path: "/" }).catch(() => {});
        })
        .catch(error => {
          this.loading = false;
          if (this.captchaOnOff) {
            this.getCode();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.linkurl {
  cursor: pointer;
  text-decoration: underline;
  margin-right: 15px;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}
::v-deep .el-input {
  background: #fff;
}

.btnLogin {
  width: 100px;
  height: 50px;
  cursor: pointer;
  user-select: none;
}
.login {
  // display: flex;
  // justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  background-image: url("../../assets/images/bj.png");
  background-size: cover;
  min-width: 1064px;
  min-height: 969px;
}
.form-content {
  // background-image: url("../../assets/images/bj-denglu.png");
  // background-size: cover;
  width: 1083px;
  height: 212px;
  margin: 0 auto;
  margin-top: 90px;
  .form-content-area {
  }
}
.title {
  margin: 0px auto 70px auto;
  text-align: center;
  // color: #707070;
  // background: linear-gradient(0deg,#1890ff 0%, #00b8ff 100%);
  font-size: 24px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: center;
  color: #00b8ff;
}

.login-form {
  display: flex;
  // margin: 0 auto;
  // border-radius: 0px;
  // background: #ffffff;
  // min-height: 390px;
  // width: 525px;
  // padding: 50px 25px 5px 25px;
  ::v-deep .el-input__inner {
    border: 0px solid #dcdfe6;
    margin-left: 45px;
    height: 50px;
    border-radius: 0px;
  }
  .el-input {
    height: 50px;

    input {
      height: 50px;
      background: #fafafa;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 50px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 80px;
  // line-height: 40px;
  position: fixed;
  bottom: 20px;
  width: 100%;
  // text-align: center;
  // color: #fff;
  // font-family: Arial;
  // font-size: 12px;
  letter-spacing: 1px;
  // opacity: 0.5;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
}
.login-code-img {
  height: 50px;
}
.codeVal {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .phoneCode {
    margin-left: 10px;
  }
}
.dialog-footer {
  text-align: center;
  .el-button {
    width: 100%;
  }
}
.update-red {
  color: #ff0000;
  i {
    color: #fc5531;
    margin-right: 5px;
  }
}
.tip {
  color: #999;
  font-size: 12px;
}
</style>
